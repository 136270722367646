export enum LocalStorageKey {
  authToken = 'JAMMY_CUSTOMER_AUTH_TOKEN',
  hotelId = 'HOTEL_ID',
  planId = 'RESERVE_PLAN_ID',
  roomTypeId = 'RESERVE_ROOM_TYPE_ID',
  roomCount = 'RESERVE_ROOM_COUNT',
  checkIn = 'RESERVE_CHECK_IN',
  checkOut = 'RESERVE_CHECK_OUT',
  adultCount = 'RESERVE_ADULT_COUNT',
  childCount = 'RESERVE_CHILD_COUNT',
  babyCount = 'RESERVE_BABY_COUNT',
  beforeReservation = 'BEFORE_RESERVATION',
  totalFee = 'RESERVE_TOTAL_FEE',
  registerEmail = 'REGISTER_EMAIL',
}
