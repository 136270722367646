import '../styles/globals.scss'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import type { AppProps } from 'next/app'
import { ApolloProvider, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createFragmentArgumentLink } from 'apollo-link-fragment-argument'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'src/theme/theme'
import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import { initializeApp, getApps } from 'firebase/app'
import useGraphqlClient from 'src/util/graphqlClient'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    })
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
})
const link = from([
  // #FIXME ts-ignoreしないとエラーになる。
  // @ts-ignore
  createFragmentArgumentLink(),
  errorLink,
  createHttpLink({
    uri: `${process.env.API_URL}/graphql`,
  }),
])

const firebaseConfig = {
  apiKey: 'AIzaSyBQ-w14v8njgMHBKhnlKMxEfqi6afjtwHw',
  authDomain: 'jammy-customer-cli.firebaseapp.com',
  projectId: 'jammy-customer-cli',
  storageBucket: 'jammy-customer-cli.appspot.com',
  messagingSenderId: '400360017460',
  appId: '1:400360017460:web:f9a9866f97f4b90323c461',
  measurementId: 'G-6S2Y4F6FSR',
}

if (getApps.length < 1) {
  initializeApp(firebaseConfig)
}

function MyApp(props: AppProps) {
  return (
    <RecoilRoot>
      <App {...props} />
    </RecoilRoot>
  )
}

function App({ Component, pageProps }: AppProps) {
  const { client } = useGraphqlClient()

  const SafeHydrate = dynamic(() => import('src/util/safeHydrate'), {
    ssr: false,
  })

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </ApolloProvider>
  )
}
export default MyApp
