import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { offsetLimitPagination } from '@apollo/client/utilities'
import { useMemo } from 'react'
import { createFragmentArgumentLink } from 'apollo-link-fragment-argument'
import { LocalStorageKey } from './persistenceKeys'

export default function useGraphqlClient() {
  const fragmentLink = createFragmentArgumentLink()

  const client = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    })

    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: localStorage.getItem(LocalStorageKey.authToken)
            ? `Bearer ${localStorage.getItem(LocalStorageKey.authToken)}`
            : '',
        },
      }
    })

    // FIXME: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
    const httpLink = createUploadLink({
      uri: `${process.env.API_URL}/graphql`,
    })

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            roomTypeList: offsetLimitPagination(),
            roomList: offsetLimitPagination(),
          },
        },
      },
    })

    return new ApolloClient({
      link: ApolloLink.from([
        fragmentLink as unknown as ApolloLink,
        authLink,
        errorLink,
        httpLink as unknown as ApolloLink,
      ]),
      cache,
    })
  }, [])

  return { client }
}
