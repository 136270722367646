import { extendTheme } from '@chakra-ui/react'

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
}

export const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: '#F4F7FB',
      },
      p: {
        color: '#2D3748',
      },
      a: {
        color: 'blue',
      },
      table: {
        color: '#2D3748',
      },
    },
  },
  components: {
    Textarea: {
      sizes: {
        md: {
          maxWidth: '780px',
          height: '140px',
          fontSize: '16px',
        },
      },
    },
    Input: {
      sizes: {
        md: {
          field: {
            maxWidth: '780px',
            height: '48px',
            fontSize: '16px',
          },
        },
      },
    },
    NumberInput: {
      sizes: {
        md: {
          field: {
            maxWidth: '780px',
            height: '48px',
            fontSize: '16px',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#4F5464',
      },
    },
    Select: {
      sizes: {
        md: {
          field: {
            height: '48px',
            fontSize: '16px',
          },
        },
      },
    },
  },
})
